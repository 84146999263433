.NewGuideTitle {
    font-size: 32px;
    margin:  80px 0 32px 0;
}
.bannerData {
    display: flex;
    width: 100%;
    margin-bottom: 100px;
}
.bannerCont {
    margin-right: 18px;
    height: 206px;
}
.bannerCont:last-child{
    margin-right: 0;
}
.bannerImage img{
    border-radius: 10px;
}
.bannerImage {
    width: 388px;
    height: 206px;
    position: relative;
}
.titleDataCont {
    max-width: 350px;
    position: absolute;
    bottom: 22px;
    left: 20px;
    color: #fff;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
}
@media screen and (min-width: 960px) and (max-width: 1231px){
    .bannerData {
        overflow-x: auto;
        height: auto;
    }
    .bannerCont {
        height: auto;
        width: 33%;
        padding-bottom: 10px;
    }
    .bannerImage {
        width: auto;
        height: auto;
    }
}
@media screen and (max-width: 960px) {
    .NewGuideTitle {
        font-size: 20px;
    }
    .bannerData {
        margin-bottom: 32px;
        overflow-x: auto;
        height: 240px;
    }
    .bannerData::-webkit-scrollbar {
      display: none;
    }
    .bannerCont {
        margin-right: 10px;
    }
    .bannerImage {
        width: 388px;
    }
}